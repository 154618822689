export enum AlerteTypeEnum {
    SOM000028='inactive',
    SOM000029='inactive',
    SOM000034='inactive',
    SOM000035='inactive',
    SOM000067='error',
    SOM000086='inactive',
    SOM000087='inactive',
    SOM000088='inactive',
    SOM000089='inactive',
    SOM000090='inactive',
    SOM000091='inactive',
    SOM000092='inactive',
    SOM000093='inactive',
    SOM000094='inactive',
    SOM000095='inactive',
    SOM000096='inactive',
    SOM000103='inactive',
    SOM000118='error',
    "com.desjardins.sommaire_perso.detail.carte_credit.payer_la_carte"='error',
    "com.desjardins.sommaire_perso.visa.switch.inactif"='inactive',
    HDS000901='inactive',
    HDS000902='inactive',
    "can_act."='inactive',
    "can_act.4001"='inactive',
    "can_act.4002"='inactive',
    "can_act.4003"='inactive',
    "can_act.4004"='inactive',
    "can_act.7000"='inactive',
    "can_act.7003"='inactive',
    "can_act.7100"='inactive',
    "can_act.7200"='inactive',
    "can_act.7300"='inactive',
    "contrat_change."='inactive',
    "contrat_change.CLO001"='inactive',
    "contrat_change.CLO002"='inactive',
    "contrat_change.CLO003"='inactive',
    "contrat_change.CLO004"='inactive',
    "contrat_change.CLO005"='inactive',
    "contrat_change.CLO006"='inactive',
    "contrat_change.CLO007"='inactive',
    "contrat_change.CLO008"='inactive',
    "contrat_change.CLO009"='inactive',
    "contrat_change.CLO010"='inactive',
    "contrat_change.CLO011"='inactive',
    "contrat_change.CLO012"='inactive',
    "mgps."='inactive',
    "mgps.POR.000100"='inactive',
    "mgps.POR.000101"='inactive',
    "mgps.POR.000102"='inactive',
    "mgps.POR.000103"='inactive',
    "mgps.POR.000104"='inactive',
    "mgps.POR.000105"='inactive',
    "ncr.sso.compte.nonexistant"='inactive',
    "ncr.1"='inactive',
    "ncr.generique"='inactive',
    GENERIC='error',
}
