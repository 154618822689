import { NOM_COOKIE_ATK_CTX_ACCESD, WindowService } from "../window/window-service";

export class SjtiService {

    public static obtenirSjti(): string | null | undefined {
        const valeurCookie = WindowService.getCookie(NOM_COOKIE_ATK_CTX_ACCESD);
        const valeurCookieJson: any = valeurCookie ? JSON.parse((WindowService.nativeWindow().atob(valeurCookie))) : null;
        return valeurCookieJson ? valeurCookieJson.sjti : null;
    }

}
