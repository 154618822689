export const NOM_COOKIE_ATK_CTX_ACCESD = 'atk_ctx_accesd';

function _window(): any {
    // Retourne l'objet global window.
    return window;
}

function _document(): any {
    // Retourne l'objet document.
    return document;
}

/**
 * Classe servant à abstraire l'objet window, qui n'est pas disponible dans tous les contexte d'exécution.
 * Utile dans des cas de tests unitaires.
 */
export class WindowService {

    public static nativeWindow(): any {
        return _window();
    }

    public static nativeDocument(): any {
        return _document();
    }

    public static getCookie(nom: string) {
        const cookieTrouve = WindowService.nativeDocument().cookie
            ?.split(";")
            ?.find((ligne: string) => ligne.trim().startsWith(`${nom}=`)) ?? null;
        return cookieTrouve ? this.extraireCookieValeur(cookieTrouve) : null;
    }

    private static extraireCookieValeur(cookieChaine: string) {
        let idx = cookieChaine.indexOf('=');

        if (idx !== -1 || idx + 1 <= cookieChaine.length) {
            return cookieChaine.slice(idx + 1)?.replace(/;$/,'') ?? null;
        }

        return null;
    }
}
