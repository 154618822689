import * as configuration from "@oel/js-configuration";

import {useEffect, useState} from "react";

import {LangueUtils} from "@oel/js-i18n";
import {Ristourne} from "../../../../modeles/ristourne";
import {en} from "./i18n/Ristourne.i18n.en";
import {fr} from "./i18n/Ristourne.i18n.fr";

export function BanniereRistourne() {

    const [ristourne, setRistourne] = useState<Ristourne>();
    const libelles = new LangueUtils(fr, en).obtenirLibelles();

    function obtenirEntetes(): Headers {

        let headers = new Headers();
        headers.set("Accept-Language", document.getElementsByTagName("html")?.item(0)?.getAttribute("lang") || "");
        return headers;
    }

    useEffect(()=> {

        const urlLienRistourne = configuration.ConfigurationService.getConfig("oel-adpm-url") + "/sommaire-perso/api/v1/ristourne";
        const ajouterListenerClicRistourne = (urlRistourne) => {
            document.getElementById("dismiss-ristourne-detail")?.addEventListener("click", function (e) {

                e.preventDefault();
                const url = configuration.ConfigurationService.getConfig("oel-adpm-url") + "/sommaire-perso/api/v1/ristourne/consulter.ajax";
                const timeoutController = new AbortController();
                const timeoutId = setTimeout(() => timeoutController.abort(), 5000);

                fetch(url, {
                    method: 'GET',
                    headers: obtenirEntetes(),
                    credentials: 'include',
                    signal: timeoutController.signal
                }).finally(() => {
                    clearTimeout(timeoutId);
                    window.location.href = urlRistourne;
                });
            });
        };

        fetch(urlLienRistourne, {
            method: 'GET',
            headers: obtenirEntetes(),
            credentials: 'include'
        }).then(response => {
            const isJson = response.headers?.get('content-type')?.includes('application/json');
            if (isJson && response.ok) {
                response.json().then(data => {
                    if (data?.url && data?.titre && data?.description) {
                        setRistourne(data);
                        ajouterListenerClicRistourne(data.url);
                    }
                });
            }            
        });
    }, []);

    return ristourne ? (
        <dsd-banner class="dsd-mb-md" variant="suggestion">
            <span slot="tag">{libelles.ristournePastille}</span>
            <span slot="title">{ristourne.titre}</span>
            <span slot="content">{ristourne.description}</span>
            <span slot="actions">
              <dsd-link-action analytics='{ "data-mw-action-clic": "ristourne_bandeau" }'>
                <a id="dismiss-ristourne-detail" href={ristourne.url}>{libelles.ristourneLien}</a>
              </dsd-link-action>
            </span>
        </dsd-banner>
    ) : null
}
