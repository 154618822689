import * as configuration from "@oel/js-configuration";

import App from "./components/app/App";
import { BrowserRouter } from "react-router-dom";
import React from "react";
import ReactDOM from "react-dom";

const routerBaseName = configuration.ConfigurationService.getConfig("oel-spa-base-url");

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename={routerBaseName ? routerBaseName : "/"}>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
