import { ConfigurationService } from "@oel/js-configuration";

export class GabaritNavigationUtil {
    private static estSessionLegacyCharge = false;
    private static estAfficherTiroirPrimeurs = true;

    private static estAfficherMenuDroite = true;
    private static identifiantMenuDroiteActif = '';

    static obtenirConfigurationGabarit() {
        return {
            typeGabarit: 'consultation',
            consultation: {
                filAriane: [],
                afficherAideContact: true,
                afficherSondageCaisse: true,
                afficherLogoAssuranceDepot: true,
                afficherMenuDroite: this.estAfficherMenuDroite,
                estSessionLegacyCharge: this.estSessionLegacyCharge,
                afficherTiroirPrimeurs: this.estAfficherTiroirPrimeurs,
                idContenuPrincipal: "titre-page-pour-retour-contenu-principal",
                entetePage: {
                    identifiantMenu: this.obtenirIdentifiantMenu(),
                },
                idsEnvoyerEvenement: ["accueil", "recherche-transaction"],
                estContexteN3: false,
                menuPrincipal: {
                    identifiantMenu: ''
                }
            },
        };
    }

    private static obtenirIdentifiantMenu() {
        return ConfigurationService.getConfig("oel-contexte-applicatif") === "ADP"
            ? "entete-consultation-adp-principal-accueil"
            : undefined;
    }

    static afficherMenuDroite(afficher: boolean, identifiantMenu: string = '') {
        
        if (this.estAfficherMenuDroite !== afficher || this.identifiantMenuDroiteActif !== identifiantMenu) {
            this.estAfficherMenuDroite = afficher;
            this.identifiantMenuDroiteActif = identifiantMenu;
            dispatchEvent(
                new CustomEvent("@navigation-gabarit/v1/appliquer-configuration", {
                    detail: {
                        consultation: {
                            afficherMenuDroite: afficher,
                            menuPrincipal: {
                                identifiantMenu: identifiantMenu
                            }
                        }
                    }
                })
            );
        }
    }

    static afficherTiroirPrimeurs(afficher: boolean) {
        if (this.estAfficherTiroirPrimeurs !== afficher) {
            this.estAfficherTiroirPrimeurs = afficher;
            dispatchEvent(
                new CustomEvent("@navigation-gabarit/v1/appliquer-configuration", {
                    detail: {
                        consultation: {
                            afficherTiroirPrimeurs: afficher
                        }
                    }
                })
            );
        }
    }
    
    static declencherSessionLegacyCharge() {
        if (!this.estSessionLegacyCharge) {
            this.estSessionLegacyCharge = true;
            dispatchEvent(
                new CustomEvent("@navigation-gabarit/v1/appliquer-configuration", {
                    detail: {
                        consultation: {
                            estSessionLegacyCharge: this.estSessionLegacyCharge
                        }
                    }
                })
            );
        }
    }

    static reinitialiserFilAriane() {
        dispatchEvent(
            new CustomEvent("@navigation-gabarit/v1/appliquer-configuration", {
                detail: {
                    consultation: {
                        filAriane: []
                    }
                }
            })
        );
    }

    static afficherPageDetailCompteEOP() {
        this.estAfficherMenuDroite = true;
        this.identifiantMenuDroiteActif = 'menudroitedetailcompte';
        this.estAfficherTiroirPrimeurs = false;
        dispatchEvent(
            new CustomEvent("@navigation-gabarit/v1/appliquer-configuration", {
                detail: {
                    consultation: {
                        afficherMenuDroite: this.estAfficherMenuDroite,
                        afficherTiroirPrimeurs: this.estAfficherTiroirPrimeurs,
                        menuPrincipal: {
                            identifiantMenu: this.identifiantMenuDroiteActif
                        },
                        aideContactV2: {
                            identifiantMenu: 'aidecontact-detail-eop',
                        }
                    }
                }
            })
        );
    }
    
    static retirerAideContact() {
        dispatchEvent(
            new CustomEvent("@navigation-gabarit/v1/appliquer-configuration", {
                detail: {
                    consultation: {
                        aideContactV2: {
                            identifiantMenu: 'aidecontact'
                        }
                    }
                }
            })
        );
    }
}
