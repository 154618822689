import "./Alerte.css";
import { AlerteTypeEnum } from "./AlerteTypeEnum";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { LangueUtils } from "@oel/js-i18n";
import { fr } from "./i18n/Alerte.i18n.fr";
import { en } from "./i18n/Alerte.i18n.en";
export interface AlerteType {
    code: string,
    libelle: string,
    focusAlerte: boolean
}

export function Alerte() {
    const libelles = new LangueUtils(fr, en).obtenirLibelles();
    const [searchParams, setSearchParams] = useSearchParams();
    const [alertes, setAlertes] = useState<AlerteType[]>([]);
    
    useEffect(() => {
        window.addEventListener("@detention/v1/afficher-alertes", afficherAlertes);
        traiterCodesMessagesDepuisURL();
        return () => {
            window.removeEventListener("@detention/v1/afficher-alertes", afficherAlertes);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setTimeout(() => {
            alertes.forEach((alerte) => {
                focusAlerte(alerte.focusAlerte);
            })
        }, 100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alertes]);
    
    const afficherAlertes = (e) => {
        setAlertes(ancientAlerteListe => {
            return ancientAlerteListe.find((alerte) => alerte.code === (e as CustomEvent).detail.code) ?
                [...ancientAlerteListe] :
                [...ancientAlerteListe, (e as CustomEvent).detail]
        });
    };
    
    function focusAlerte(focusAlerte: boolean) {
        if (focusAlerte) {
            document.getElementsByClassName("alerte")[0].getElementsByTagName("h2")[0].focus();
        }
    }

    function traiterCodesMessagesDepuisURL() {
        const codeErreurURL = searchParams.get('code_err');
        const codeAvertissementURL = searchParams.get('code_avert');

        if (codeErreurURL) {
            ajouterCodeErreur(codeErreurURL);
            searchParams.delete('code_err');
        }
        if (codeAvertissementURL) {
            ajouterCodeErreur(codeAvertissementURL);
            searchParams.delete('code_avert');
        }

        setSearchParams(searchParams, {replace: true});  // replace:true Prévient l'injection d'une entrée dans l'historique de navigation
    }

    function ajouterCodeErreur(codeErreur: string) {
        codeErreur.split(',').forEach(code => {
            setAlertes(ancientAlerteListe => {
                return ancientAlerteListe.find((alerte) => alerte.code === code) ?
                    [...ancientAlerteListe] :
                    [...ancientAlerteListe, {
                        libelle: libelles[code] || libelles['GENERIC'],
                        code: AlerteTypeEnum[code] ? code : 'GENERIC',
                        focusAlerte: false
                    }]
            });
        });
    }
    
    return (
        alertes.length ?
            <div className="alerte">
                {alertes.map((alerte, index) => (
                    <dsd-alert full-width type={AlerteTypeEnum[alerte.code]} key={index}>
                        {alerte.libelle}
                    </dsd-alert>
                ))}
            </div> : null
    );
}
