import "./Cadenas.scss";

import { LangueUtils } from "@oel/js-i18n";
import { en } from "./i18n/Cadenas.i18n.en";
import { fr } from "./i18n/Cadenas.i18n.fr";

export function Cadenas() {
    
    const libelles = new LangueUtils(fr, en).obtenirLibelles();

    return (
        <div className="pleine-page">
            <div className="loading">

                <div className="content">
                    <div className="lock">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                    </div>
                    <div className="spinner-content">
                        <dsd-loading></dsd-loading>
                    </div>
                </div>
                <div className="text-loading">
                    <p>{libelles.preparation}</p>
                </div>

            </div>
        </div>
    );
}
